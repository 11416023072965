import { useEffect, useState } from "react"

export function useSubscriptionExpired<T extends { end_date: Date | null }>({
  product,
}: {
  product: T
}) {
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(true)
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    // add 1 day for the end date to be inclusive of the last day and 14 days grace period
    const newEndDate = new Date(product?.end_date?.toISOString() || "")
    newEndDate.setDate(newEndDate.getDate() + 15)
    if (!product || !product.end_date || newEndDate >= now) {
      setIsSubscriptionExpired(false)
    }
    return
  }, [now, product])

  useEffect(() => {
    let timerInterval = setInterval(() => {
      setNow(new Date())
    }, 60000)

    return () => clearInterval(timerInterval)
  }, [])

  return { isSubscriptionExpired }
}
