import Link, { LinkProps } from "next/link"
import React from "react"
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useTenant } from "./common/Tenant"

type RouteAwareLinkProps = ChakraLinkProps &
  LinkProps & {
    route?: string
  }

const RouteAwareLink: React.FC<RouteAwareLinkProps> = React.forwardRef(
  ({ route: providedRoute, href, children, ...otherProps }, _ref) => {
    const { route, asPath, reload } = useRouter()
    const { tenant } = useTenant()
    const themeName = tenant?.name
    const routeOverride = providedRoute || route

    const handleClick = (e) => {
      // Default routing on same path, reload page
      e.preventDefault()
      if (asPath === href) {
        reload()
      } else {
        // Temporary resolution for LGLCP-666 by opting out of navigation via NextJs Link
        // 404 error occurs due to NextJS router getInfoRoute failing, which fallsback to
        // hard navigation.
        window.location.href = href
      }
    }

    let ariaCurrent
    if (routeOverride == "/documents/[...params]") {
      if (themeName == "threesd" || themeName == "divorcenet" || themeName == "nolodivorce") {
        ariaCurrent =
          href.toString().includes("/documents/divorce") || href == "" ? "page" : "false"
      } else {
        ariaCurrent = href == "/documents" || href == "" ? "page" : "false"
      }
    } else if (routeOverride.startsWith("/analytics")) {
      if (routeOverride === "/analytics" && href === "/") {
        // If the routeOverride is exactly /analytics and href is "/", mark it as active
        ariaCurrent = "page"
      } else if (href.startsWith("/analytics") && routeOverride === href) {
        // If the href matches a sub-path of /analytics, mark it as active
        ariaCurrent = "page"
      } else {
        ariaCurrent = "false"
      }
    } else {
      const routeParts = routeOverride.split("/")
      ariaCurrent =
        "/" + routeParts[routeParts.length - 1] === href ||
        (href == "/" &&
          (routeOverride == "/threesd" ||
            routeOverride == "/willmaker" ||
            routeOverride == "/divorcenet" ||
            routeOverride == "/nolodivorce"))
          ? "page"
          : "false"
    }

    return (
      <ChakraLink
        as={Link}
        {...otherProps}
        aria-current={ariaCurrent}
        href={href}
        onClick={handleClick}
        prefetch={false}
      >
        {children}
      </ChakraLink>
    )
  }
)

export default RouteAwareLink
