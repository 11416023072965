import Image from "next/legacy/image"
import { useQuery } from "@blitzjs/rpc"
import { ClientSession, useSession } from "@blitzjs/auth"
import React, { Suspense } from "react"
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Divider,
  useDisclosure,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Link,
  useTheme,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import ChevronDownIcon from "app/icons/ChevronDownIcon"
import RouteAwareLink from "./RouteAwareLink"
import {
  getMobileNavItemsNoDowload,
  getMobileNavItemsWithDownload,
  getNavItems,
} from "../core/themes/theme"
import getUserProducts from "app/core/queries/getUserProducts"
import { getValidDownloadProduct } from "lib/brandLookup"
import { useSubscriptionExpired } from "app/utils/hooks/subscription/useSubscriptionExpired"
import { useProductInterviews } from "app/utils/hooks/useProductInterviews"
import { ProductSlug } from "productSlug.enum"
import { useTenant } from "./common/Tenant"

const downloadSlug = ProductSlug.WILLMAKER_DESKTOP_DOWNLOAD
const MenuItemLink = ({
  children,
  href,
  isExternal = false,
  isDisable = false,
}: {
  children: string
  href: string
  isExternal?: boolean
  isDisable?: boolean
}) => {
  if (isExternal) {
    return (
      <>
        <MenuItem className="main-nav">
          <Link href={href} isExternal>
            {children}
          </Link>
        </MenuItem>
      </>
    )
  }
  if (isDisable) {
    return (
      <>
        <Link
          href={undefined}
          color="var(--chakra-colors-gray-400)"
          style={{ cursor: "not-allowed" }}
        >
          {children}
        </Link>
      </>
    )
  }
  return (
    <>
      <MenuItem
        className="main-nav"
        onClick={() => {
          window.location.replace(href)
        }}
      >
        {children}
      </MenuItem>
    </>
  )
}

const getDownloadProduct = (userProducts, themeName) => {
  const download = userProducts?.find((e) => e.product.slug.trim() === downloadSlug)
  const hasValidDownloadProduct = getValidDownloadProduct(themeName)

  return hasValidDownloadProduct.length && download && Object.keys(download).length > 0
}

const SignupMenuSubItems = ({
  theme,
  hasDownload,
  shouldDisableDownload,
}: {
  theme: string
  hasDownload: boolean
  shouldDisableDownload: boolean
}) => {
  const accountLabel = theme == "willmaker" ? "My Account" : "Account"

  switch (theme) {
    case "threesd":
    case "divorcenet":
    case "nolodivorce":
      return (
        <>
          <MenuItemLink href={"/api/shopify/login"}>{accountLabel}</MenuItemLink>
          <MenuItemLink href={"/documents"}>Documents</MenuItemLink>
          <MenuItemLink href={"/help"}>Help</MenuItemLink>
          <MenuItemLink href={"/api/auth/logout"}>Log Out</MenuItemLink>
        </>
      )
    case "willmaker":
    default:
      return (
        <>
          <MenuItemLink href={"/api/shopify/login"}>{accountLabel}</MenuItemLink>
          <MenuItemLink href={"https://www.willmaker.com/support"} isExternal={true}>
            Support
          </MenuItemLink>
          {hasDownload ? (
            <MenuItemLink href={`/${downloadSlug}`} isDisable={shouldDisableDownload}>
              Download WillMaker
            </MenuItemLink>
          ) : (
            ""
          )}
          <MenuItemLink href="/api/auth/logout">Log Out</MenuItemLink>
        </>
      )
  }
}

const SignupMenu = ({
  hasDownload,
  isSubscriptionExpired,
  session,
  themeName
}: {
  hasDownload: boolean
  isSubscriptionExpired: boolean
  session: ClientSession
  themeName: string
}) => {
  if (session.userId) {
    return (
      <>
        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu autoSelect={false}>
              <MenuButton
                py={2}
                transition="all 0.3s"
                color={"gray.900"}
                _expanded={{ color: "brand.blue" }}
              >
                <HStack spacing={"8px"}>
                  <Image
                    alt={"avatar"}
                    layout="fixed"
                    width={24}
                    height={24}
                    src="/images/avatar.svg"
                  />
                  <Text whiteSpace={"nowrap"}>{`${session.first_name} ${session.last_name}`}</Text>
                  <Box display={{ base: "none", md: "flex" }}>
                    <ChevronDownIcon w={"12px"} h={"12px"} />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={"white"}
                borderColor={"white"}
                width={"260px"}
                mt={"22px"}
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
                borderBottomLeftRadius={{ xl: "8px" }}
                borderBottomRightRadius={{ xl: "8px" }}
                p={0}
                borderWidth="0px"
                boxShadow={"0px 10px 20px rgba(0, 0, 0, 0.14);"}
              >
                <Stack p={"24px"} spacing={2}>
                  <Text
                    color={"gray.900"}
                    fontSize={"22px"}
                    lineHeight={"28px"}
                    fontWeight={"bold"}
                  >{`${session.first_name} ${session.last_name}`}</Text>
                  <Text>{session.email}</Text>
                </Stack>
                <MenuDivider borderColor={"gray.400"} m={0} />
                <Stack p={"24px"} spacing={2}>
                  <SignupMenuSubItems
                    theme={themeName}
                    hasDownload={hasDownload}
                    shouldDisableDownload={isSubscriptionExpired}
                  />
                </Stack>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </>
    )
  } else {
    return (
      <>
        <Button as={"a"} fontSize={"sm"} fontWeight={400} variant={"link"} color={"gray.900"}>
          <Link href={"/api/auth/keycloak"}>Sign In</Link>
        </Button>
        <Button
          display={{ base: "none", md: "inline-flex" }}
          fontSize={"sm"}
          fontWeight={600}
          color={"brand.blue"}
          border="2px"
          borderColor={"brand.blue"}
          borderRadius={"4px"}
          width={"168px"}
          bg={"white"}
          _hover={{
            bg: "white",
          }}
        >
          <Link href={"#"}>Buy Now</Link>
        </Button>
      </>
    )
  }
}

export default function Navbar() {
  const theme = useTheme()
  const {tenant} = useTenant()
  const themeName = tenant?.name
  const { isOpen, onToggle, onClose } = useDisclosure()
  const session = useSession()
  const productsInterviews = useProductInterviews()
  const [userProducts] = useQuery(
    getUserProducts,
    {},
    {
      staleTime: 900000,
    }
  )

  let divLink = "/documents/divorce"

  const hasDownload = getDownloadProduct(userProducts, themeName)
  const willProduct = userProducts?.find((e) => e.product.slug === "will")!
  const { isSubscriptionExpired } = useSubscriptionExpired({ product: willProduct })

  if (productsInterviews && productsInterviews?.divorce) {
    const productSlug = productsInterviews?.divorce[0]?.productSlug
    if (productSlug && productsInterviews.divorce[0]?.id) {
      const urlEndPoint = productsInterviews.divorce[0].id
      divLink = "/documents/" + productSlug + "/" + urlEndPoint
    }
  }

  return (
    <>
      <Box
        as="header"
        boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.14)"}
        position={"sticky"}
        top="0"
        backgroundColor="white"
        zIndex={3}
      >
        <Container size="xl">
          <Flex height={"80px"} align={"center"}>
            <Flex flex={{ base: 1 }} justify={"start"}>
              <RouteAwareLink href="/" >
                <div className="headerLogoContainer">
                  <Image
                    alt={"Nolo"}
                    src={theme.logoImage.url}
                    width={theme.logoImage.width}
                    height={theme.logoImage.height}
                  />
                </div>
              </RouteAwareLink>

              <Flex display={{ base: "none", lg: "flex" }} ml="6rem" alignItems="center">
                <DesktopNav divLink={divLink} themeName={themeName} />
              </Flex>
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
              display={{ base: "none", md: "inline-flex" }}
            >
              <Suspense fallback="Loading...">
                <SignupMenu
                  session={session}
                  hasDownload={hasDownload}
                  isSubscriptionExpired={isSubscriptionExpired}
                  themeName={themeName}
                />
                <IconButton
                  onClick={onToggle}
                  borderWidth="0px"
                  _hover={{
                    background: "transparent",
                  }}
                  icon={
                    isOpen ? (
                      <CloseIcon w={"12px"} h={"12px"} />
                    ) : (
                      <HamburgerIcon w={"16px"} h={"16px"} />
                    )
                  }
                  variant={"unstyled"}
                  aria-label={"Toggle Navigation"}
                  display={{ lg: "none" }}
                />
              </Suspense>
            </Stack>

            <Flex flex={{ base: 1 }} justify={"flex-end"} display={{ base: "flex", md: "none" }}>
              <IconButton
                onClick={onToggle}
                borderWidth="0px"
                _hover={{
                  background: "transparent",
                }}
                icon={
                  isOpen ? (
                    <CloseIcon w={"16px"} h={"16px"} />
                  ) : (
                    <HamburgerIcon w={"24px"} h={"24px"} />
                  )
                }
                variant={"unstyled"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>
          </Flex>
          <Box display={{ base: "none", md: "block", lg: "none" }}>
            <Box display={isOpen ? "block" : "none"}>
              <Divider pt="16px" borderColor="gray.400" opacity={1} />
              <Suspense fallback="Loading...">
                <TabletNav onClose={onClose} divLink={divLink} themeName={themeName}/>
              </Suspense>
            </Box>
          </Box>
        </Container>
      </Box>
      <Stack display={isOpen ? "block" : "none"}>
        <Suspense fallback="Loading...">
          <MobileNav onClose={onClose} divLink={divLink} themeName={themeName}/>
        </Suspense>
      </Stack>
    </>
  )
}

const DesktopNav = ({ divLink, themeName }) => {
  return (
    <Stack direction={"row"} spacing={21}>
      {getNavItems(themeName).map((navItem) => (
        <RouteAwareLink
          href={navItem.href == "/divorceform" ? divLink : navItem.href}
          key={navItem.label}
          p={2}
          fontSize={"16px"}
          fontWeight={400}
          color={"gray.900"}
          _activeLink={{ color: "brand.activeNav" }}
          _hover={{
            textDecoration: "none",
            color: "brand.activeNav",
          }}
        >
          {navItem.label}
        </RouteAwareLink>
      ))}
    </Stack>
  )
}

const MobileNav = ({ onClose, divLink, themeName }) => {
  const session = useSession()
  const [userProducts] = useQuery(
    getUserProducts,
    {},
    {
      staleTime: 900000,
    }
  )

  const hasDownload = getDownloadProduct(userProducts, themeName)
  const items = getMobileNavItems(themeName, session, hasDownload, divLink)

  return (
    <Stack
      bg={"white"}
      display={{ base: "block", md: "none" }}
      h={"calc(100vh - 80px)"}
      w="100vw"
      position={"fixed"}
      pt={"24px"}
      zIndex={9999}
    >
      {items.map((section, index) => (
        <div key={index}>
          {section.map((navItem, index) => (
            <MobileNavItem key={index} {...navItem} onClose={onClose} />
          ))}
          {index + 1 !== items.length && (
            <Box p="16px">
              <Divider borderColor="gray.400" opacity={1} />
            </Box>
          )}
        </div>
      ))}
    </Stack>
  )
}

const TabletNav = ({ onClose, divLink, themeName }) => {
  return (
    <Stack
      py="14px"
      direction={"row"}
      textAlign="center"
      spacing={20}
      display={{ base: "none", md: "block", lg: "none" }}
    >
      {getNavItems(themeName).map((navItem) => (
        <RouteAwareLink
          href={navItem.href == "/divorceform" ? divLink : navItem.href}
          key={navItem.label}
          p={2}
          fontSize={"16px"}
          fontWeight={400}
          color={"gray.900"}
          _activeLink={{ color: "brand.blue" }}
          _hover={{
            textDecoration: "none",
            color: "brand.blue",
          }}
        >
          {navItem.label}
        </RouteAwareLink>
      ))}
    </Stack>
  )
}

interface NavItem {
  label: string
  href: string
  onClose?: any
  userName?: boolean
  className?: string
}

const MobileNavItem = ({ label, href, onClose, userName, className }: NavItem) => {
  return (
    <Stack spacing={4} px={"16px"}>
      {href ? (
        <RouteAwareLink
          href={href}
          py={"16px"}
          fontWeight={400}
          fontSize={"18px"}
          color={"gray.900"}
          _activeLink={{ color: "brand.blue", fontWeight: 700 }}
          _hover={{
            textDecoration: "none",
            color: "brand.blue",
          }}
          onClick={onClose}
        >
          {label}
        </RouteAwareLink>
      ) : (
        <Text
          className={className ? className : ""}
          py={"8px"}
          fontWeight={userName ? 700 : 400}
          fontSize={userName ? "24px" : "18px"}
          color={userName ? "gray.900" : "gray.700"}
        >
          {label}
        </Text>
      )}
    </Stack>
  )
}

const getMobileNavItems = (themeName, session, hasDownload, divLink) =>
  hasDownload
    ? getMobileNavItemsWithDownload(themeName, session, downloadSlug, divLink)
    : getMobileNavItemsNoDowload(themeName, session, divLink)
